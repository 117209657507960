import m, { MarkedOptions } from 'marked';
import DOMPurify from 'dompurify';
import { getProxyUrl } from '@/api/service/urls';
import { GithubRepo } from '@/types/publishing.types';

const domparser = new DOMParser();

class MarkdownRenderer extends m.Renderer {
  public link(href: string, title: string, text: string): string {
    const html = super.link(href, title, text);
    return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
  }
}

const renderer = new MarkdownRenderer();

m.setOptions({
  renderer,
  breaks: true,
  smartLists: true,
  baseUrl: '',
  // sanitization works better if you pass the whole
  // result to dompurify, like below
  sanitize: false,
});

DOMPurify.setConfig({
  ADD_ATTR: [
    'target',
    'slot',
    'tabs',
    'expandable',
    'code',
    'copyicon',
    'background',
    'hidecode',
    'alignitems',
    'justifycontent',
    'padding',
    'height',
  ],
  ADD_TAGS: [
    'docs-component-previewer',
    'docs-copy-code',
    'docs-component-picker',
    'docs-token-download',
    'docs-user-feedback',
    'gsk-list',
    'gsk-list-item',
    'gsk-icon',
    'gsk-icon-button',
    'gsk-alert',
    'gsk-button',
    'gsk-code-sandbox',
    'iframe',
  ],
});

export function marked(str: string, sanitize: boolean): string;
export function marked(str: string, options: MarkedOptions): string;
export function marked(str: string, options: MarkedOptions | boolean) {
  if (typeof options === 'boolean') {
    return options ? DOMPurify.sanitize(m(str)) : m(str);
  } else {
    if (options.sanitize) {
      const opts = { ...options };
      opts.sanitize = false;
      return DOMPurify.sanitize(m(str, opts));
    }

    return m(str, options);
  }
}

// rtrim and resolveUrl taken from marked.js
// marked doesn't resolve urls that are in <a>nchor tags for some reason
// so we are doing it ourselves

const baseUrls: Record<string, string> = {};
function rtrim(str: string, c: string, invert: boolean): string {
  if (str.length === 0) {
    return '';
  }

  // Length of suffix matching the invert condition.
  let suffLen = 0;

  // Step left until we fail to match the invert condition.
  while (suffLen < str.length) {
    const currChar = str.charAt(str.length - suffLen - 1);
    if (currChar === c && !invert) {
      suffLen++;
    } else if (currChar !== c && invert) {
      suffLen++;
    } else {
      break;
    }
  }
  /**
   * @deprecated
   */
  return str.substr(0, str.length - suffLen);
}
export function resolveUrl(base: string, href: string): string {
  if (!baseUrls[' ' + base]) {
    // we can ignore everything in base after the last slash of its path component,
    // but we might need to add _that_
    // https://tools.ietf.org/html/rfc3986#section-3
    if (/^[^:]+:\/*[^/]*$/.test(base)) {
      baseUrls[' ' + base] = base + '/';
    } else {
      baseUrls[' ' + base] = rtrim(base, '/', true);
    }
  }
  base = baseUrls[' ' + base];
  const relativeBase = base.indexOf(':') === -1;

  if (href.slice(0, 2) === '//') {
    if (relativeBase) {
      return href;
    }
    return base.replace(/^([^:]+:)[\s\S]*$/, '$1') + href;
  } else if (href.charAt(0) === '/') {
    if (relativeBase) {
      return href;
    }
    return base.replace(/^([^:]+:\/*[^/]*)[\s\S]*$/, '$1') + href;
  } else {
    return base + href;
  }
}

export function replaceLinkedRepoRelativeLinks(
  html: string,
  repo: GithubRepo,
  url: string,
  versionQuery = '',
) {
  const doc = domparser.parseFromString(html, 'text/html');
  setVideoAttributes(doc);
  const [owner, name] = repo.full_name.split('/');
  [...doc.querySelectorAll('a')].forEach(a => {
    const href = a.getAttribute('href') || '';
    try {
      new URL(href);
    } catch (e) {
      // if it throws, it's not a valid url and likely a relative path
      const parts = [
        owner,
        name,
        'tree',
        versionQuery || repo.default_branch,
        href.startsWith('/') ? '' : removeFileFromPath(url),
        href,
      ]
        .join('/')
        .replace(/\/+/g, '/');
      a.href = `ENTERPRISE-GIT/${parts}`;
      a.target = '_blank';
      a.rel = 'nofollow';
    }
  });
  proxyGithubImages(doc, owner, name, url, versionQuery || repo.default_branch);
  return doc.body.innerHTML;
}

function removeFileFromPath(path: string): string {
  if (path.includes('/')) {
    return path.replace(/\/[^/]+$/, '');
  }
  return '';
}

export function replaceLinkedUrlRelativeLinks(html: string, url: string): string {
  const doc = domparser.parseFromString(html, 'text/html');
  setVideoAttributes(doc);
  [...doc.querySelectorAll('img')].forEach(img => {
    const src = img.getAttribute('src') || '';
    try {
      new URL(src);
      img.src = getProxyUrl(src);
    } catch (e) {
      img.src = getProxyUrl(resolveUrl(url, src));
    }
  });
  [...doc.querySelectorAll('a')].forEach(a => {
    const href = a.getAttribute('href') || '';
    try {
      new URL(href);
    } catch (e) {
      a.href = resolveUrl(url, href);
    }
    a.target = '_blank';
    a.rel = 'nofollow';
  });
  return doc.body.innerHTML;
}

export function createDocument(html: string): Document {
  return domparser.parseFromString(html, 'text/html');
}

export function setVideoAttributes(doc: Document): Document {
  [...doc.querySelectorAll('video')].forEach(video => {
    video.controls = true;
    video.muted = true;
    video.autoplay = true;
    video.loop = true;
  });
  return doc;
}

export function setIframeAttributes(doc: Document): Document {
  [...doc.querySelectorAll('iframe')].forEach(iframe => {
    iframe.setAttribute('sandbox', 'allow-scripts allow-same-origin');
  });
  return doc;
}

export function proxyGithubImages(
  doc: Document,
  owner: string,
  name: string,
  file: string,
  rev: string,
): Document {
  [...doc.querySelectorAll('img')].forEach(img => {
    const href = img.getAttribute('src') || '';
    try {
      new URL(href);
    } catch (e) {
      const parts = [
        owner,
        name,
        rev,
        href.startsWith('/') ? '' : removeFileFromPath(file),
        href,
      ]
        .join('/')
        .replace(/\/+/g, '/');
      img.src = getProxyUrl(`/ENTERPRISE-GIT/${parts}`);
    }
  });
  [...doc.querySelectorAll('video')].forEach(source => {
    const href = source.getAttribute('src') || '';
    try {
      new URL(href);
    } catch (e) {
      const parts = [
        owner,
        name,
        rev,
        href.startsWith('/') ? '' : removeFileFromPath(file),
        href,
      ]
        .join('/')
        .replace(/\/+/g, '/');
      source.src = getProxyUrl(`/ENTERPRISE-GIT/${parts}`);
    }
  });
  return doc;
}
